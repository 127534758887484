interface PageWrapperProps {
    children: React.ReactNode;
}

const backgroundStyle = {
  backgroundImage: 'url("./Background")'
}

const PageWrapper: React.FC <PageWrapperProps> = ({children}) => {
  return (
    <div className="PageWrapper">
        {children}
    </div>
  );
};

export default PageWrapper;