import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import outgoingLinks from './pageContent/outgoingLinks.json';
import rightsInfo from './pageContent/rightsInfo.json';


const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App dataRequests={rightsInfo} outgoingLinks={outgoingLinks} />
  </React.StrictMode>
);