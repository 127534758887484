import React, { ReactElement } from 'react';
import { privacyPolicyFormat, privacyPolicyItem } from './IPrivacyPolicyFormat';
import privacyPolicy from './privacyPolicy.json';
const parsedPrivacyPolicy = privacyPolicy.privacyPolicy as unknown as privacyPolicyFormat;

const buildTopicEntries = (items: string[]): ReactElement[] => {

    const out = [];

    for (let i = 0; i < items.length; i++) {

        const newBulletItem = (
            <div key={"Topic_" + i} className='BulletItem'>
                <p className="BulletItemContent">
                    {items[i]}
                </p>
            </div>
        );

        out.push(newBulletItem);
    };

    return out;
}

const buildDocumentHeader = (privacyPolicy: privacyPolicyFormat) => {

    return (
        <>
            <div className='PageTitleing'>

            
                <p className='TextContent'>
                    {privacyPolicy.businessDisclaimer.disclaimer}
                    <br />
                    {privacyPolicy.businessDisclaimer.businessId}
                </p>
                <br/>
                <h2 className='SecondaryTitle'>
                    {privacyPolicy.intro.mainTitle}
                </h2>
                <br/>
                <p className='TextContent'>
                    {privacyPolicy.intro.documentDescription}
                </p>

            </div>

            <div className='TextContentContainer'>
                <div className='BulletListContainer'>
                    {buildTopicEntries(privacyPolicy.intro.topics.content)}
                </div>
            </div>
        </>
    );
};

const buildDocumentSectionContent = (content: string[]) => {

    const out = [];

    for (let i = 0; i < content.length; i++) {

        const currentString = content[i];
        out.push((
            <>
                <br />

                <div className='InsetTextContainer'>
                    <div className='TextContent'>
                        {currentString}
                    </div>
                </div>
            </>
        ));
    };
    return out;
};

const conditionalLineBrake = (toCheck: any) => {
    if(toCheck) {
        return (<br/>);
    }
    else {
        return;
    };
};

const buildDocumentSection = (item: privacyPolicyItem): ReactElement => {

    return (
        <>
            <div className='TextContentContainer'>
                <h3 className='ThirdTitle'>
                    {item.title}
                </h3>
                {conditionalLineBrake(item.description)}
                <p className='TitleDescription'>
                    {item.description}
                </p>

                {buildDocumentSectionContent(item.content)}
            </div>
        </>
    );
};

const buildDocumentContent = (privacyPolicy: privacyPolicyFormat): ReactElement[] => {

    const out = [];
    const documentContent = privacyPolicy.mainContent;

    for (let i = 0; i < documentContent.length; i++) {

        const currentDocumentItem = documentContent[i];
        const builtDocumentItem = buildDocumentSection(currentDocumentItem);
        out.push(builtDocumentItem);
    };

    return out;
}

const PrivacyPolicy: React.FC = () => {

    return (
        <div className='PageContent'>
            {buildDocumentHeader(parsedPrivacyPolicy)}
            {buildDocumentContent(parsedPrivacyPolicy)}

        </div>
    );
};

export default PrivacyPolicy;