import { ReactComponent as NavigationArrowSVG } from '../../graphics/arrowNavigate.svg';
import { Link } from 'react-router-dom';

interface bulletItems {

    [key: string]: {
        title: string;
        content: string;
        routeTo: string;
    };
};

interface Props {
    headerOne: string;
    headerTwo: string;
    contentText: {
        contentHeader: string;
        bulletItems: bulletItems;
    };
};

const getBulletItem = (title: string, content: string, linksTo: string) => {
    
    return (
        <div key={title} className='BulletItem'>
            <div className="BulletItemTitleRow">
                <p className='BulletItemTitle'>
                    {title}
                </p>

                <Link to={linksTo} className={`NavigationButton`}>
                    {<NavigationArrowSVG style={{ pointerEvents: 'none' }} />}
                </Link>

            </div>


            <p className='BulletItemContent'>
                {content}
            </p>

        </div>
    )
};

const buildBulletItemContainer = (items: bulletItems) => {

    const itemNames = Object.keys(items);
    const buildBulletItems = [];

    for (let i = 0; i < itemNames.length; i++) {

        const currentItemName = itemNames[i];
        const bulletItem = getBulletItem(items[currentItemName].title, items[currentItemName].content, items[currentItemName].routeTo);
        buildBulletItems.push(bulletItem);
    };

    return buildBulletItems;


};

const DataRequests: React.FC<Props> = (Props) => {

    return (

        <div className="PageContent">

            <div className="PageTitleing">
                <div className="SecondaryTitle">
                    <h2>{Props.headerTwo}</h2>
                </div>
            </div>

            <div className="TextContentContainer">

                <div className="BulletListContainer">
                    {buildBulletItemContainer(Props.contentText.bulletItems)}
                </div>

            </div>

        </div>
    );
};

export default DataRequests;