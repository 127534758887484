import React from 'react';

const ExerciseRights: React.FC = () => {


    return (
        <div className='PageContent'>

            <div className='TextContentContainer'>
                <p className='TextContent'>
                    If you would like to exercise any of your data protection rights please get in touch with the email provided below.
                </p>
                <br />
                <p className='TextContent'>
                    We will get back to you with further instructions and details about your request.
                </p>

                <h3 className='ThirdTitle'>
                    Email: info@intrahelsinki.com
                </h3>
            </div>

        </div>
    );
};

export default ExerciseRights;