import React, { ReactElement, useState } from 'react';

const displayConditionedOnCookieWithdrawal = (consentHasBeenCleared: boolean, withdrawConsent: any): ReactElement => {

    if (!consentHasBeenCleared) {
        return (
            <button className="CTAButton" id="accept" onClick={withdrawConsent}>
                Withdraw consent
            </button>
        );
    }
    else {
        return (
            <div className='TextContentContainer'>
                <h3 className='ThirdTitle'>
                    Done! You've successfully withdrawn your consent for data collection and processing.
                </h3>
            </div>
        );
    };
};

const WithdrawConsent: React.FC = () => {

    const [consentHasBeenCleared, setConsentClearedState] = useState(false);

    const withdrawConsent = (): void => {

        const cookies = document.cookie.split(';');
    
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    
            document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        };
    
        setConsentClearedState(true);
    };

    return (
        <div className='PageContent'>

            <div className='TextContentContainer'>

                <p className='TextContent'>
                    If you would like to withdraw your consent for data collection and processing please click the button below.
                </p>
                <br />
                <p className='TextContent'>
                    We track consent on a browser by browser basis. You'll need to withdraw consent on the browser you have given it on.
                </p>

            </div>

            <div className="ButtonContainer">
                {displayConditionedOnCookieWithdrawal(consentHasBeenCleared, withdrawConsent)}
            </div>

        </div>
    );
};

export default WithdrawConsent;