import { Link } from 'react-router-dom';
import BusinessDisclaimer from './AboutPage.json';
import MainContent from './AboutPage.json';
const parsedBusinessDisclaimer = BusinessDisclaimer.BusinessDisclaimer as businessDisclaimer;


const parsedMainContent: string[] = MainContent.MainContent;


const directToHomePage = () => {

    if (window.location.href.startsWith('https://intrahelsinki.com')) {
        return (
            <Link to={"/"}>intrahelsinki.com</Link>
        );
    }
    else {
        return (
            <a href="https://intrahelsinki.com">intrahelsinki.com</a>
        )
    }
};

interface businessDisclaimer {
    Disclaimer: string;
    BusinessId: string;
};

const AboutPage: React.FC = () => {

    console.log(window.location.href);

    return (
        <div className='PageContent'>

            <div className='PageTitleing'>

                <p className='TextContent'>
                    {parsedBusinessDisclaimer.Disclaimer}
                    <br />
                    {parsedBusinessDisclaimer.BusinessId}
                </p>

            </div>
            <br />
            <div className='TextContentContainer'>
                <p className='TextContent'>
                    {parsedMainContent[0]}
                </p>

                <br />
                <h3 className='ThirdHeader'>
                    {directToHomePage()}
                </h3>

            </div>

        </div>
    );
};

export default AboutPage;