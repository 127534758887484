import React, { useEffect, useState } from 'react';
import { ReactComponent as DropdownOpenSVG } from './dropdownOpen.svg';
import { ReactComponent as DropdownClosedSVG } from './dropdownClosed.svg';
import { Link } from 'react-router-dom';

interface OutgoingLinkItem {
  name: string;
  link: string;
  order: number;
  isOnNavbar: boolean;
  isOnFooter: boolean;
}

//I apologize to my future self for the navmenu button being jank.
//Without the test variable the menu doesn't fade in. Wtf?

const Navbar: React.FC<{ outgoingLinks: OutgoingLinkItem[] }> = ({ outgoingLinks }) => {
  const [open, setOpen] = useState(false);
  const [menuFadeFinished, setMenuFadeState] = useState(true);
  const [test, setTest] = useState(false);

  const closeMenuOnClick = (event: any) => {

    if(open === true && event.target.id !== "NavbarMenuButton" && menuFadeFinished === false) {
      setMenuFadeState(true);
      setOpen(false);
    };
  };

  window.addEventListener('click', closeMenuOnClick);

  const links = outgoingLinks.sort((a, b) => a.order - b.order);

  const NavBarMenuItem = (properties: { ItemName: string; ItemPath: string }) => {
    return (
      <Link to={properties.ItemPath} className="NavbarMenuItem" key={properties.ItemName}>
        {properties.ItemName}
      </Link>
    );
  };

  const handleMenuFadeFinish = () => {
    setMenuFadeState(true);
  };

  useEffect(() => {
    console.log("open", open);

      setTest(open)

  }, [open]);

  useEffect(() => {
    console.log("menu fade finished", menuFadeFinished);
 
  }, [menuFadeFinished]);

  const navbarMenu = () => {
    if (open) {
      return (
        <div className={`NavbarMenu ${test ? 'show' : ''}`}>
          {buildNavBarMenuItems()}
        </div>
      );
    }
    else if(!menuFadeFinished) {
      return (
        <div className={`NavbarMenu ${menuFadeFinished ? 'clear' : ''}`} onTransitionEnd={handleMenuFadeFinish}>
          {buildNavBarMenuItems()}
        </div>
      );
    };
  };

  const buildNavBarMenuItems = () => {
    return links
      .filter((link) => link.isOnNavbar)
      .map((link) => NavBarMenuItem({ ItemName: link.name, ItemPath: link.link }));
  };

  const toggleMenu = () => {
    console.log("Toggle menu running now")
    setMenuFadeState(false);
    setOpen(!open);
  };

  const navbarButton = () => {
    return (
      <button id="NavbarMenuButton" onClick={toggleMenu} className={`NavigationButton ${open ? 'menuOpen' : ''}`}>
        {open ? <DropdownOpenSVG style={{ pointerEvents: 'none' }} /> : <DropdownClosedSVG style={{ pointerEvents: 'none' }} />}
      </button>
    );
  };

  return (
    <div className="Navbar">
      <div className="NavbarButton">
        {navbarButton()}
        {navbarMenu()}
      </div>
      <div className="NavbarLogoContainer">
        <div className="NavbarLogo">INTRA-HELSINKI</div>
      </div>
    </div>
  );
};

export default Navbar;