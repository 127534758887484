import { Link } from 'react-router-dom';

interface outgoingLinkItem {
  name: string,
  link: string,
  order: number,
  isOnNavbar: boolean,
  isOnFooter: boolean
}

const Footer: React.FC<{outgoingLinks: outgoingLinkItem[]}> = (outgoingLinks) => {

  const links = outgoingLinks.outgoingLinks
  links.sort((a, b) => a.order - b.order);

  const footerItem = (properties: {ItemName: string, ItemPath: string}) => {
    return (<Link to={properties.ItemPath} key={properties.ItemName}>{properties.ItemName}</Link>);
  }

  const buildFooterItems = () => {
    const items = [];
      for(let i = 0; i < links.length; i++) {
        if(links[i].isOnFooter) {
          const test = footerItem({ItemName: links[i].name, ItemPath: links[i].link});
          items.push(test);
        }
      }
    return items;
  }

  return (
    <div className="Footer">

        <div className="FooterLogoContainer">
            <div className="FooterLogo">INTRA-HELSINKI</div>
        </div>

        <div className="FooterContentContainer">
            {buildFooterItems()}
        </div>
        
    </div>
  );
};

export default Footer;