import { Link } from 'react-router-dom';
import Content from './HomePage.json';

const parsedContent = Content.Content as homePageTextContent;

interface homePageTextContent {
  HeaderTwo: string;
  ContentText: string;
  Question: string;
  Answer: string;
};

const HomePage: React.FC = () => {

  return (
    <div className="PageContent">

      <div className="PageTitleing">

        <div className="SecondaryTitle">
          <h2>{parsedContent.HeaderTwo}</h2>
        </div>
      </div>
      <br />
      <div className='TextContentContainer'>
        <p className='TextContent'>
          {parsedContent.ContentText}
        </p>

        <h3 className='ThirdTitle'>
          {parsedContent.Question}
        </h3>
        <br />
        <p className='TextContent'>
          {parsedContent.Answer + " "} <Link to={"/data-requests"}>data rights</Link>.
        </p>
      </div>

    </div>
  );
};

export default HomePage;