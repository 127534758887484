import React, { ReactElement } from 'react';
import PageNotFoundContent from './PageNotFoundContent.json';
import { Link } from 'react-router-dom';
const parsedPageNotFoundContent = PageNotFoundContent.PageNotFoundContent as PageNotFoundContent;

interface PageNotFoundContent {

    Titles: {
        MainTitle: string;
        SecondaryTitle: string;
    };
    MainContent: string[];
};

const buildPageTitles = (mainTitle: string, secondaryTitle: string): ReactElement => {

    return (
        <div className='PageTitleing'>
            <h1 className='MainTitle'>
                {mainTitle}
            </h1>

            <br/>

            <h2 className='SecondaryTitle'>
                {secondaryTitle}
            </h2>

            <h3 className='ThirdTitle'>
                <Link to={"/"}>Go back to the home page</Link>
            </h3>
        </div>
    );
};

const buildPageContent = (content: string[]): ReactElement => {

    const out = [];

    for(let i = 0; i < content.length; i++) {

        const currentElement = content[i];

        out.push((
            <p className='TextContent'>
                {currentElement}
            </p>
        ))

    };

    return (
        <div className='TextContentContainer'>
            {out}
        </div>
    )
};

const PlaylistCantBeFound: React.FC = () => {

    return (
        <div className='PageContent'>
            {buildPageTitles(parsedPageNotFoundContent.Titles.MainTitle, parsedPageNotFoundContent.Titles.SecondaryTitle)}
            {buildPageContent(parsedPageNotFoundContent.MainContent)}

        </div>
    );
};

export default PlaylistCantBeFound;