import ContactPageContent from './ContactPage.json';
const parsedMainContent = ContactPageContent as MainContent;

interface MainContent {
    ContactPageContent: string[];
};

const BuildContactPageContent = (content: string[]) => {

    const out = (
        <>
            <h3 className='ThirdTitle'>
                {content[0]}
            </h3>

            <h3 className='ThirdTitle'>
                <a href="mailto:info@intrahelsinki.com">{content[1]}</a>
            </h3>
        </>
    );

    return out;
};

const ContactPage: React.FC = () => {

    return (
        <div className='PageContent'>

            <div className='PageTitleing'>

                {BuildContactPageContent(parsedMainContent.ContactPageContent)}

            </div>

        </div>
    );
};

export default ContactPage;