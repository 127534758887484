import React, { ReactElement } from 'react';
import { TermsAndConditionsContentFormat, termsAndConditionsItem } from './ITermsAndConditions';
import TermsAndConditionsContent from './TermsAndConditions.json';
const parsedTermsAndConditions = TermsAndConditionsContent.TermsAndConditionsContent as TermsAndConditionsContentFormat;

const buildDocumentHeader = (TermsAndConditions: TermsAndConditionsContentFormat) => {

    return (
            <div className='PageTitleing'>

                <br/>
                <h2 className='SecondaryTitle'>
                    {TermsAndConditions.Intro.MainTitle}
                </h2>
                <br/>
                <p className='TextContent'>
                    {TermsAndConditions.Intro.DocumentDescription}
                </p>

            </div>
    );
};

const buildDocumentSectionContent = (content: string[]) => {

    const out = [];

    for (let i = 0; i < content.length; i++) {

        const currentString = content[i];
        out.push((
            <>
                <br />

                <div className='InsetTextContainer'>
                    <div className='TextContent'>
                        {currentString}
                    </div>
                </div>
            </>
        ));
    };
    return out;
};

const conditionalLineBrake = (toCheck: any) => {
    if(toCheck) {
        return (<br/>);
    }
    else {
        return;
    };
};

const buildDocumentSection = (item: termsAndConditionsItem): ReactElement => {

    return (
        <>
            <div className='TextContentContainer'>
                <h3 className='ThirdTitle'>
                    {item.Title}
                </h3>
                {conditionalLineBrake(item.Description)}
                <p className='TitleDescription'>
                    {item.Description}
                </p>

                {buildDocumentSectionContent(item.Content)}
            </div>
        </>
    );
};

const buildDocumentContent = (temrsAndConditions: TermsAndConditionsContentFormat): ReactElement[] => {

    const out = [];
    const documentContent = temrsAndConditions.MainContent;

    for (let i = 0; i < documentContent.length; i++) {

        const currentDocumentItem = documentContent[i];
        const builtDocumentItem = buildDocumentSection(currentDocumentItem);
        out.push(builtDocumentItem);
    };

    return out;
}

const TermsAndConditions: React.FC = () => {

    return (
        <div className='PageContent'>
            {buildDocumentHeader(parsedTermsAndConditions)}
            {buildDocumentContent(parsedTermsAndConditions)}

        </div>
    );
};

export default TermsAndConditions;