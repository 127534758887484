import './style/StyleVariables.css';
import './style/PageElements.css';
import './style/ZeroDefaultValues.css';
import './style/Buttons/Buttons.css';
import './style/Buttons/ButtonContainers.css'
import './style/Menus/Navbar.css';
import './style/TextElements/TextElementContainers.css';
import './style/TextElements/TextElements.css';
import './style/Logos/NavbarLogo.css';
import './style/Logos/FooterLogo.css';
import './style/TextElements/BulletLists.css';

import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import PageWrapper from './components/PageWrapper/PageWrapper';
import HomePage from './components/HomePage/HomePage';
import DataRequests from './components/DataRequests/DataRequests';
import ContactPage from './components/ContactPage/ContactPage';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PlaylistCantBeFound from './components/PlaylistCantBeFound/PlaylistCantBeFound';

import { Route, BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router";
import ExerciseRights from './components/ExerciseRights/ExerciseRights';
import WithdrawConsent from './components/WithdrawConsent/WithdrawConsent';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import AboutPage from './components/AboutPage/AboutPage';
import PageNotFoundPage from './components/PageNotFoundPage/PageNotFoundPage';
import { ReactComponentElement, useEffect } from 'react';

interface outgoingLinkItem {
  name: string;
  link: string;
  order: number;
  isOnNavbar: boolean;
  isOnFooter: boolean;
};

interface dataRequests {
  headerOne: string;
  headerTwo: string;
  contentText: {
    contentHeader: string;
    bulletItems: {
      bulletItemOne: {
        title: string;
        content: string;
        routeTo: string;
      };
      bulletItemTwo: {
        title: string;
        content: string;
        routeTo: string;
      }
      bulletItemThree: {
        title: string;
        content: string;
        routeTo: string;
      }
    };
  };
};

const validRoutes = ['/', '/data-requests', '/data-requests/withdraw-consent', '/data-requests/exercise-rights', '/contact', '/about', '/privacy-policy', '/terms-of-service', '/page-not-found', '/service/playlist-not-found'];

const ValidateCurrentRoute = (): any => {

   const currentPath = useLocation().pathname;
   const navigate = useNavigate();

   useEffect(() => {

    if(!validRoutes.includes(currentPath)) {

      console.log("Redirect!");
      navigate('/page-not-found');
     };
   }, [currentPath])
   
};

const App: React.FC<{ dataRequests: dataRequests; outgoingLinks: outgoingLinkItem[] }> = (properties) => {

  const { dataRequests, outgoingLinks } = properties;

  return (
    <>
      <BrowserRouter>
      <ValidateCurrentRoute/>
        <PageWrapper>
          <Navbar outgoingLinks={outgoingLinks} />

          <Routes>
            <Route path="/" element={< HomePage />} ></Route>
            <Route path="/data-requests" element={< DataRequests {...dataRequests} />}></Route>
            <Route path="/data-requests/withdraw-consent" element={< WithdrawConsent />}></Route>
            <Route path="/data-requests/exercise-rights" element={< ExerciseRights />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/privacy-policy" element={< PrivacyPolicy />}></Route>
            <Route path="/terms-of-service" element={< TermsAndConditions />}></Route>
            <Route path="/page-not-found" element={< PageNotFoundPage />}></Route>
            <Route path="/service/playlist-not-found" element={< PlaylistCantBeFound />}></Route>
          </Routes>
          <Footer outgoingLinks={outgoingLinks} />
        </PageWrapper>
      </BrowserRouter>
    </>
  );
};

export default App;