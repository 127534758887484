import React, { ReactElement } from 'react';
import PlaylistCantBeFoundContent from './PlaylistCantBeFound.json';
const parsedPlaylistCantBeFoundContent = PlaylistCantBeFoundContent.PlaylistCantBeFoundContent as PlaylistCantBeFoundContent;

interface PlaylistCantBeFoundContent {

    Titles: {
        MainTitle: string;
        SecondaryTitle: string;
    };
    MainContent: string[];
};

const buildPageTitles = (mainTitle: string, secondaryTitle: string): ReactElement => {

    return (
        <div className='PageTitleing'>
            <h1 className='MainTitle'>
                {mainTitle}
            </h1>

            <br/>

            <h2 className='SecondaryTitle'>
                {secondaryTitle}
            </h2>
        </div>
    );
};

const buildPageContent = (content: string[]): ReactElement => {

    const out = [];

    for(let i = 0; i < content.length; i++) {

        const currentElement = content[i];

        out.push((
            <p className='TextContent'>
                {currentElement}
            </p>
        ))

    };

    return (
        <div className='TextContentContainer'>
            {out}
        </div>
    )
};

const PlaylistCantBeFound: React.FC = () => {

    return (
        <div className='PageContent'>
            {buildPageTitles(parsedPlaylistCantBeFoundContent.Titles.MainTitle, parsedPlaylistCantBeFoundContent.Titles.SecondaryTitle)}
            {buildPageContent(parsedPlaylistCantBeFoundContent.MainContent)}

        </div>
    );
};

export default PlaylistCantBeFound;